import { defineStore } from "pinia"
import {
  type AdminCamera,
  type ExNvrDeviceConfig,
  type Kit,
  type Nvr,
  type Project,
  type Router,
  PatchPayload,
  ExNvrSystemStatus,
  CameraExid,
  KitMetricPeriod,
} from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { useNuxtApp } from "#app"

type KitSummaryState = {
  kit: Partial<Kit>
  nvr: Partial<Nvr>
  project: Partial<Project>
  router: Partial<Router>
  cameras: Array<AdminCamera>
  dialog: boolean
  tabFullScreen: boolean
  hideSidebar: boolean
  loading: boolean
  isInline: boolean
  totalCameras: number
  exNvrToken: string
  exNvrDeviceConfigByCamera: Record<CameraExid, ExNvrDeviceConfig>
  exNvrSystemStatus: ExNvrSystemStatus
  exNvrDevices: ExNvrDeviceConfig[]
  previousKitsPageFullPath: string
  selectedMetricsPeriod: KitMetricPeriod
}

export const useKitSummaryStore = defineStore({
  id: "kitSummary",
  state: (): KitSummaryState => ({
    kit: {},
    nvr: {},
    project: {},
    router: {},
    cameras: [],
    dialog: false,
    tabFullScreen: false,
    hideSidebar: false,
    loading: false,
    isInline: false,
    totalCameras: 0,
    exNvrToken: null,
    exNvrDeviceConfigByCamera: {},
    exNvrSystemStatus: null,
    exNvrDevices: [],
    previousKitsPageFullPath: null,
    selectedMetricsPeriod: KitMetricPeriod.Last1Hour,
  }),
  actions: {
    async refreshKit() {
      await this.loadKit(this.kit.id)
    },
    async loadKit(id) {
      try {
        this.loading = true

        this.kit = await AdminApi.kits.getKit(id)
        this.nvr = this.kit.nvr || {}
        this.router = this.kit.router || {}
        this.project = this.kit.project
        this.fetchCameras().then(() => {
          this.resetExNvrDetails()
          if (this.nvr?.id) {
            this.fetchExNvrDetails()
          }
        })
      } catch (error) {
        if (error.response.status === 400) {
          useNuxtApp().nuxt2Context.$notifications.error({
            statusCode: 404,
            message: "This kit is not found in Evercam",
            // @ts-ignore
            homePageLink: false,
          })
        } else if (error?.response?.status === 404) {
          useNuxtApp().nuxt2Context.$notifications.error({
            statusCode: 404,
            message: "Kit not found",
            // @ts-ignore
            homePageLink: true,
          })
        } else {
          useNuxtApp().nuxt2Context.$notifications.error({
            text: "Load kit details failed!",
            error: error,
          })
        }
      } finally {
        this.loading = false
      }
    },
    async updateKit(patch: PatchPayload<Kit>) {
      try {
        this.loading = true
        await AdminApi.kits.updateKit(this.kit.id, patch)
        await this.refreshKit()
        useNuxtApp().nuxt2Context.$notifications.success(
          "Kit has been updated."
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to update Kit!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async fetchCameras() {
      try {
        const cameras = await AdminApi.cameras.getCameras({
          params: {
            kitId: this.kit.id,
            limit: 50,
            page: 1,
            sort: "created_at|desc",
          },
        })
        this.totalCameras = cameras.total
        this.cameras = cameras.items
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Could not load Cameras!",
          error,
        })
      }
    },
    async createRouter(params) {
      try {
        const payload = {
          ...params,
          kitId: this.kit.id,
          cameras: this.cameras,
        }

        await AdminApi.routers.createRouter(payload)
        this.loadKit(this.kit.id)
        useNuxtApp().nuxt2Context.$notifications.success(
          "Router has been added."
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to create router!",
          error,
        })
      }
    },
    async updateRouter(change) {
      try {
        await AdminApi.routers.updateRouter(change.id || this.router.id, change)
        this.router = {
          ...this.router,
          ...change,
        }

        useNuxtApp().nuxt2Context.$notifications.success(
          "Router has been updated."
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to update router!",
          error,
        })
      }
    },
    async updateNvr(change) {
      await AdminApi.nvrs
        .updateNvr(this.nvr.id, change)
        .then(async (nvr) => {
          this.nvr = {
            ...this.nvr,
            ...nvr,
          }
          useNuxtApp().nuxt2Context.$notifications.success(
            "NVR has been updated."
          )
        })
        .catch((error) => {
          useNuxtApp().nuxt2Context.$notifications.error({
            text: "Failed to update NVR!",
            error,
          })
        })
    },
    async deleteKit(payload) {
      try {
        this.loading = true
        await AdminApi.kits.deleteKit(this.kit.id, payload)
        useNuxtApp().nuxt2Context.$notifications.success(
          `Kit "${this.kit.serial}" has been deleted successfully`
        )
        this.invalidateCache()
        useNuxtApp().nuxt2Context.app.router.push("/kits")
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to delete kit!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    invalidateCache() {
      this.kit = {}
      this.project = {}
      this.nvr = {}
      this.router = {}
      this.cameras = []
    },
    resetExNvrDetails() {
      this.exNvrToken = null
      this.exNvrSystemStatus = null
      this.exNvrDeviceConfigByCamera = {}
      this.exNvrDevices = []
    },
    async fetchExNvrDetails() {
      try {
        await this.loginToExNvr()
        if (!this.exNvrToken) {
          return
        }
        Promise.all([
          this.fetchExNvrSystemStatus(),
          this.fetchExNvrDevices(),
          ...this.cameras.map((camera) =>
            this.fetchExNvrDeviceConfig(camera as AdminCamera)
          ),
        ])
      } catch (error) {
        console.log(error)
      }
    },
    getExNvrApiPayload() {
      return {
        apiUrl: this.nvr.httpUrl || "",
        username: this.nvr.username,
        password: this.nvr.password,
      }
    },
    async loginToExNvr() {
      try {
        const { apiUrl, username, password } = this.getExNvrApiPayload()

        const response = await ExNvrApi.users.login({
          apiUrl,
          username,
          password,
        })
        this.exNvrToken = response?.accessToken
      } catch (error) {
        console.error(error)
      }
    },
    async fetchExNvrDeviceConfig(camera: AdminCamera) {
      try {
        if (!camera?.nvrDeviceId) {
          return
        }

        const { apiUrl } = this.getExNvrApiPayload()

        const data = await ExNvrApi.devices.getDeviceConfig({
          deviceId: camera.nvrDeviceId,
          apiUrl,
          token: this.exNvrToken,
        })
        this.exNvrDeviceConfigByCamera = {
          ...this.exNvrDeviceConfigByCamera,
          [camera?.exid]: data,
        }
      } catch (error) {
        console.error(error)
      }
    },
    async fetchExNvrSystemStatus() {
      try {
        const { apiUrl } = this.getExNvrApiPayload()
        const data = await ExNvrApi.devices.getSystemStatus({
          apiUrl,
          token: this.exNvrToken,
        })
        this.exNvrSystemStatus = data
      } catch (error) {
        console.error(error)
      }
    },
    async fetchExNvrDevices() {
      try {
        const { apiUrl } = this.getExNvrApiPayload()
        this.exNvrDevices = await ExNvrApi.devices.getDevices({
          apiUrl,
          token: this.exNvrToken,
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    metricsPeriodFromDate(): string {
      const matches = this.selectedMetricsPeriod.match(/now-(\d+)([mhd])/)
      const value = parseInt(matches[1] || "1")
      const unit = matches[2]

      let timeUnit
      switch (unit) {
        case "m":
          timeUnit = "minutes"
          break
        case "h":
          timeUnit = "hours"
          break
        case "d":
          timeUnit = "days"
          break
        default:
          timeUnit = "hours"
      }

      return useNuxtApp()
        .nuxt2Context.$moment(new Date())
        .subtract(value, timeUnit)
        .toISOString()
    },
    metricsPeriodToDate(): string {
      return useNuxtApp().nuxt2Context.$moment(new Date()).toISOString()
    },
  },
})
