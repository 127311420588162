import { defineStore } from "pinia"
import {
  AdminCamera,
  CameraExid,
  DateType,
  CameraFeatureFlag,
  DetectionLabel,
  TrackingsByLabel,
  DetectionsFilters,
  SegmentsByLabel,
} from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useNuxtApp } from "#app"
import moment from "moment-timezone"
import { SiteAnalyticsMode } from "@evercam/shared/types/siteAnalytics"
import { AiApi } from "@evercam/shared/api/aiApi"
import { AxiosError } from "axios"

type SiteAnalyticsState = {
  cameras: AdminCamera[]
  isLoading: boolean
  selectedCamera: AdminCamera
  selectedTimestamp: DateType
  snapshotsInterval: { from: DateType; to: DateType }
  trackingsByLabel: TrackingsByLabel
  segmentsByLabel: SegmentsByLabel
  timelineFromDate?: string
  timelineToDate?: string
  selectedMode: SiteAnalyticsMode
}

export const useSiteAnalyticsStore = defineStore({
  id: "siteAnalytics",
  state: (): SiteAnalyticsState => ({
    cameras: [],
    isLoading: false,
    selectedCamera: null,
    selectedTimestamp: new Date("2024-07-02T12:00:00").toISOString(),
    snapshotsInterval: { from: undefined, to: undefined },
    timelineFromDate: undefined,
    timelineToDate: undefined,
    trackingsByLabel: {},
    segmentsByLabel: {},
    selectedMode: SiteAnalyticsMode.Detections,
  }),
  getters: {
    selectedCameraExid(): string {
      return this.selectedCamera?.exid
    },
    breadcrumbsTitle(): string {
      const { exid, name } = this.selectedCamera
      const page = "Site Analytics"

      return `${page} - ${name} (${exid})`
    },
  },
  actions: {
    async fetchCameras() {
      if (this.cameras.length) {
        return
      }
      try {
        this.isLoading = true
        let cameras = await AdminApi.cameras.getCameras({
          params: {
            featureFlags: [CameraFeatureFlag.ObjectDetection],
          },
        })
        this.cameras = cameras.items
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to fetch site analytics cameras!",
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectCamera(exid: CameraExid) {
      if (!this.cameras.length) {
        await this.fetchCameras()
      }
      try {
        this.trackingsByLabel = {}
        this.updatePlayerSnapshotsInterval()
        this.selectedCamera = this.cameras.find((c) => c.exid === exid)
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to load camera!",
          error,
        })
      }
    },
    async selectTimestamp(t: DateType) {
      const newTimestamp = moment
        .tz(t, this.selectedCamera.timezone)
        .toISOString()

      if (newTimestamp !== this.selectedTimestamp) {
        this.selectedTimestamp = newTimestamp
        this.updatePlayerSnapshotsInterval()
      }

      if (this.selectedMode === SiteAnalyticsMode.Detections) {
        await this.fetchTrackingsForSnapshotsInterval()
      } else if (this.selectedMode === SiteAnalyticsMode.Segments) {
        await this.fetchSegmentsForSnapshotsInterval()
      }
    },
    async fetchTrackingsForSnapshotsInterval() {
      try {
        let params = {
          fromDate: moment(this.snapshotsInterval.from).toISOString(),
          toDate: moment(this.snapshotsInterval.to).toISOString(),
        } as DetectionsFilters

        if (this.selectedMode === SiteAnalyticsMode.Cranes) {
          params = {
            ...params,
            labels: [DetectionLabel.TowerCrane],
          }
        }

        this.trackingsByLabel = await AiApi.detections.getDetectionsTracking(
          this.selectedCameraExid,
          params
        )
      } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
          console.warn("No detections trackings found for this time interval")

          return
        }
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to fetch detections trackings.",
          error,
        })
      }
    },
    async fetchSegmentsForSnapshotsInterval() {
      try {
        let params = {
          fromDate: moment(this.snapshotsInterval.from)
            .startOf("hour")
            .toISOString(),
          toDate: moment(this.snapshotsInterval.to).endOf("hour").toISOString(),
        } as DetectionsFilters

        this.segmentsByLabel = await AiApi.detections.getSegmentsMasks(
          this.selectedCameraExid,
          params
        )
      } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
          console.warn("No Segments found for this time interval")

          return
        }
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to fetch segments.",
          error,
        })
      }
    },
    updatePlayerSnapshotsInterval(): void {
      const target =
        this.selectedTimestamp ||
        moment
          .tz(new Date(), this.selectedCamera.timezone)
          .subtract(9, "minute")
          .toISOString()
      const from = moment(target).subtract(2, "minutes")
      const to = moment(target).add(8, "minutes")
      const now = moment(new Date())

      this.snapshotsInterval = {
        from: from.format(),
        to: moment.min(to, now).format(),
      }
    },
  },
  syncWithUrl: [
    "selectedTimestamp",
    "timelineFromDate",
    "timelineToDate",
    "selectedMode",
  ],
})
