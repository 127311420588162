import Vue from "vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { extend } from "vee-validate"
import {
  required,
  email,
  confirmed,
  numeric,
  is_not,
  max,
  is,
} from "vee-validate/dist/rules"
import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin((app) => {
  const { $moment } = app.nuxt2Context
  Vue.component("ValidationProvider", ValidationProvider)
  Vue.component("ValidationObserver", ValidationObserver)

  extend("max", {
    ...max,
    params: ["length"],
    message: (field, { length }) => {
      return `${field} should be ${length} characters long or less`
    },
  })
  extend("required", {
    ...required,
    message: "{_field_} field is required",
  })
  extend("sms_command", {
    ...required,
    message: "Please type or select SMS command.",
  })
  extend("email", {
    ...email,
    message: "Invalid email",
  })
  extend("confirmed", {
    ...confirmed,
    message: "Please enter the same password as above",
  })
  extend("numeric", {
    ...numeric,
    message: "{_field_} field may only contain numeric characters",
  })
  extend("is_not", {
    ...is_not,
    message: "New password should be different from current password",
  })
  extend("equal_to", {
    ...is,
    params: ["other"],
    message: (value, { other }) => {
      return `${value} entered no equal to: ${other}`
    },
  })
  extend("correct_id", {
    validate: (value) => value.match(/^[-_a-z0-9]+$/) !== null,
    message: (field) =>
      `${field} field cannot contain capital letters, spaces and dots`,
  })
  extend("date_format", {
    params: ["format"],
    validate: (value, { format }) => {
      return $moment(value, format, true).isValid()
    },
    message: (field, { format }) => {
      return `${field} should be in format ${format}`
    },
  })
  extend("requiredRecipients", {
    validate: (value) => {
      const emails = value.map((obj) => obj.email ?? obj)

      return required.validate(value) && email.validate(emails)
    },
    message: "Enter at least one valid recipient's email",
  })

  extend("event_dates_compare", {
    params: ["target", "type"],
    validate: (value, { target, type }) => {
      if (!$moment(value).isValid() || !$moment(target).isValid()) {
        return true
      }
      if (type === "left") {
        return $moment(value).isAfter($moment(target))
      }

      return $moment(value).isBefore($moment(target))
    },
    message: "left at date should be greater than arrived at date",
  })

  extend("time", {
    validate: (value) => /^([01]\d|2[0-3]):([0-5]\d)$/.test(value),
    message: "Invalid time!",
  })

  extend("date", {
    validate: (value) => $moment(value).isValid(),
    message: "Invalid date!",
  })

  extend("ip_or_domain", {
    validate: (value) =>
      value.match(
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
      ) !== null ||
      value.match(
        /^((\*)|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|((\*\.)?([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,63}?))$/
      ) !== null,
    message: (field) => `${field} form of 149.5.43.10 or portlarochelle.com`,
  })

  extend("hyperlink", {
    validate: (value) => {
      const a = document.createElement("a")
      a.href = value

      const pattern = new RegExp(
        "^(https?://)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(:\\d+)?(/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(#[-a-z\\d_]*)?",
        "i"
      ) // fragment locator

      return !!pattern.test(value) && a.host && a.host != window.location.host
    },
    message: "URL is not valid",
  })
  extend("number", {
    message: "{_field_} field may only contain numeric characters",
    validate(value) {
      // Allow numeric values, including negatives
      const regex = /^-?\d*\.?\d*$/

      return regex.test(String(value))
    },
  })
  extend("requiredCameras", {
    ...required,
    message: "Select at least one camera",
  })
  extend("requiredDays", {
    ...required,
    message: "Select at least one day",
  })
})
