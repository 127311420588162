import { defineStore } from "pinia"
import { useReportStore } from "@/stores/report"
import { toTitleCase } from "@evercam/shared/utils"
import { useRoute } from "#app"
export type Bookmark = {
  name: string
  url: string
  route: string
}

export type BookmarkState = {
  bookmarks: Bookmark[]
  filteredBookmarks: Bookmark[]
  isBookmarksDisplayed: boolean
  isPathBookmarked: boolean
}

export const useBookmarksStore = defineStore({
  id: "bookmark",
  state: (): BookmarkState => ({
    filteredBookmarks: [],
    bookmarks: JSON.parse(localStorage?.getItem("bookmarks")) ?? [],
    isBookmarksDisplayed: true,
    isPathBookmarked: false,
  }),

  actions: {
    syncBookmarksWithRoute() {
      const route = useRoute()
      this.filteredBookmarks = this.bookmarks?.filter(
        (bookmark) => bookmark.route === route.path
      )
      this.isPathBookmarked = !!this.bookmarks.find(
        (item) => item.url === this.getPathname()
      )
    },
    removeFromBookmarks(url = "") {
      const path = url.length > 0 ? url : this.getPathname()
      this.bookmarks = this.bookmarks?.filter(
        (bookmarkItem: Bookmark) => bookmarkItem.url !== path
      )
      this.syncBookmarksWithRoute()
      localStorage.setItem("bookmarks", JSON.stringify(this.bookmarks))
    },
    saveToBookmarks() {
      const reportStore = useReportStore()
      const path = this.getPathname()
      const route = window.location.pathname
      const { page, limit, sort, ...filters } = reportStore.searchFilters

      let title = `All ${route.split("/")[1]}`

      const areEmptyFilters =
        Object.keys(filters).length === 0 ||
        Object.values(filters).every(
          (value) =>
            ["", null, undefined].includes(value) ||
            (Array.isArray(value) && !value.length)
        )

      if (!areEmptyFilters) {
        title = Object.entries(filters)
          .filter(
            ([_, value]) =>
              !["", null, undefined].includes(value) &&
              (Array.isArray(value) ? !!value.length : true)
          )
          .map(([key, value]) => {
            const formattedKey = toTitleCase(key)
            const formattedValue = Array.isArray(value)
              ? value.map(toTitleCase).join(" + ")
              : toTitleCase(value.toString())

            return `${formattedKey}: ${formattedValue}`
          })
          .join(", ")
      }
      const sanitizedPath = path.endsWith("?") ? path.slice(0, -1) : path
      this.bookmarks = [
        ...this.bookmarks,
        {
          name: title,
          url: sanitizedPath,
          route,
        },
      ]
      this.syncBookmarksWithRoute()
      localStorage.setItem("bookmarks", JSON.stringify(this.bookmarks))
    },
    isBookmarkAlreadyExist(url: string) {
      return !!this.bookmarks?.find((item: Bookmark) => item.url === url)
    },
    toggleBookmarks() {
      if (this.isBookmarkAlreadyExist(this.getPathname())) {
        this.removeFromBookmarks()
      } else {
        this.saveToBookmarks()
      }
    },
    getPathname() {
      return `${document.location.pathname}${document.location.search}`
    },
  },
})
