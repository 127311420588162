import { defineStore } from "pinia"
import {
  SmartSearchOptionData,
  SmartSearchQueryReturnType,
  SmartSearchQueryComponentType,
  SmartSearchQueryComponentData,
  CameraExid,
} from "@evercam/shared/types"
import { generateUuid } from "@evercam/shared/utils"

export type SmartSearchState = {
  optionSelectorDialog: boolean
  selectedOption?: SmartSearchOptionData
  returnType: SmartSearchQueryReturnType
  optionSelectorType: SmartSearchQueryComponentType
  onOptionSelected: (itemValue: unknown) => void
  queries: {
    uuid: string
    title: string
    items: string
    description?: string
  }[]
}

export const useSmartSearchStore = defineStore({
  id: "smartSearch",
  state: (): SmartSearchState => ({
    optionSelectorDialog: false,
    returnType: SmartSearchQueryReturnType.FirstLastSeen,
    selectedOption: null,
    optionSelectorType: SmartSearchQueryComponentType.Object,
    onOptionSelected: (_) => {},
    queries: [],
  }),
  actions: {
    openOptionSelectorDialog({
      onOptionSelected,
      selectedOption,
      optionSelectorType,
    }: {
      onOptionSelected: (optionValue: unknown) => void
      optionSelectorType:
        | SmartSearchQueryComponentType
        | SmartSearchQueryComponentType
      selectedOption?: SmartSearchOptionData
    }) {
      this.selectedOption = undefined
      let actualOptionSelectorType = optionSelectorType

      if (optionSelectorType === SmartSearchQueryComponentType.Time) {
        actualOptionSelectorType = SmartSearchQueryComponentType.Time
      } else if (optionSelectorType === SmartSearchQueryComponentType.Area) {
        actualOptionSelectorType = SmartSearchQueryComponentType.Area
      } else if (optionSelectorType === SmartSearchQueryComponentType.Object) {
        actualOptionSelectorType = SmartSearchQueryComponentType.Object
      }

      this.selectedOption = selectedOption
      this.optionSelectorType = actualOptionSelectorType
      this.optionSelectorDialog = true

      this.onOptionSelected = (optionValue) => {
        onOptionSelected(optionValue)
        this.optionSelectorDialog = false
        this.selectedOption = undefined
      }
    },
    loadQueries() {
      this.queries =
        JSON.parse(localStorage?.getItem("smartSearchQueries")) ?? []

      return this.queries
    },
    saveQuery({
      title,
      components,
      description,
      cameraExid,
      author,
      totalItems,
      createdAt,
    }: {
      title: string
      components: SmartSearchQueryComponentData[]
      cameraExid: CameraExid
      description?: string
      author: string
      totalItems: number
      createdAt: string
    }) {
      const uuid = generateUuid()
      const queries =
        JSON.parse(localStorage?.getItem("smartSearchQueries")) ?? []

      queries.push({
        uuid,
        title,
        description,
        cameraExid,
        components,
        author,
        totalItems,
        createdAt,
      })

      this.queries = queries

      localStorage.setItem("smartSearchQueries", JSON.stringify(queries))
    },
    removeQuery(uuid: string) {
      console.log({ uuid })
      const queries =
        JSON.parse(localStorage?.getItem("smartSearchQueries")) ?? []
      const updatedQueries = queries.filter((query) => query.uuid !== uuid)
      this.queries = updatedQueries
      localStorage.setItem("smartSearchQueries", JSON.stringify(updatedQueries))
      this.loadQueries()
    },
  },
  getters: {
    optionSelectorTitle(): string {
      switch (this.optionSelectorType) {
        case SmartSearchQueryComponentType.Object:
          return "Select an object"
        case SmartSearchQueryComponentType.Condition:
          return "Select a condition"
        case SmartSearchQueryComponentType.Time:
          return "Set time schedule"
        case SmartSearchQueryComponentType.Area:
          return "Define area"
        default:
          return ""
      }
    },
  },
})
