import posthog from "posthog-js"
import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin } from "#app"
export interface UserParams {
  firstname?: string
  lastname?: string
  email: string
}
export default defineNuxtPlugin(async (app) => {
  const { $config, isDev } = app.nuxt2Context

  const accountStore = useAccountStore()
  const identify = (
    params: UserParams = getUserIdentificationParams()
  ): void => {
    if (params?.email) {
      posthog.identify(params.email, params)
      posthog.startSessionRecording()
    }
    posthog.opt_in_capturing()
  }

  const reset = (): void => {
    posthog.reset()
  }

  const getUserIdentificationParams = (): UserParams => {
    const { firstname, lastname, email } = accountStore || {}

    return {
      firstname,
      lastname,
      email: email,
    }
  }

  if (isDev) {
    app.provide("posthog", {
      identify: () => {},
      reset: () => {},
    })

    return
  }

  posthog.init($config?.public.posthogPublicApiKey, {
    api_host: "https://app.posthog.com",
    autocapture: true,
    disable_session_recording: true,
    loaded: () => identify(),
  })

  app.provide("posthog", {
    identify,
    reset,
  })
})
